<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link
            :to="{
              name: 'edit-fuel-expenses',
              params: { id: $route.params.expenseId },
            }"
          >
            {{ fuelExpenseName }}</router-link
          >
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingFuelExpenseRow"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <FuelExpensesRowForm
          ref="FuelExpensesRowForm"
          :fuel-expense="fuelExpense"
          :form="form"
        ></FuelExpensesRowForm>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingFuelExpenseRow"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_FUEL_EXPENSE_ROW } from "@/modules/fuel-expenses-row/store/fuelexpensesrow.module";
import { FETCH_FUEL_EXPENSE } from "@/modules/fuel-expenses/store/fuelexpenses.module";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import KTCard from "@/view/content/Card.vue";
import FuelExpensesRowForm from "./FuelExpensesRowForm.vue";

export default {
  name: "FuelExpensesRowAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    FuelExpensesRowForm,
  },
  data() {
    return {
      errors: [],
      fuelExpense: {},
      form: {
        fuelExpenseId: null,
        fuelTypeId: 1,
        price: null,
        totalPrice: 0,
        totalQty: 0,
        tankCharges: [
          {
            dateCharged: null,
            fuelableId: null,
            fuelableType: null,
            fuelableName: null,
            staffId: null,
            staffName: null,
            liters: null,
            mileage: null,
          },
        ],
      },
    };
  },
  computed: {
    fuelExpenseName() {
      return this.fuelExpense.invoiceNumber + " " + this.fuelExpense.gasStation;
    },
  },
  mounted() {
    this.$store
      .dispatch(FETCH_FUEL_EXPENSE, this.$route.params.expenseId)
      .then((data) => {
        this.fuelExpense = data.data;

        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: this.$i18n.t("MENU.ITEM.EXP.FUELS_EXPENSES"),
            route: { name: "list-fuel-expenses" },
          },
          {
            title: this.fuelExpenseName,
            route: {
              name: "edit-fuel-expenses",
              params: { id: this.$route.params.expenseId },
            },
          },
          { title: this.$i18n.t("LABELS.ADD_NEW") },
        ]);
      });
  },
  methods: {
    onSave(next) {
      let vm = this;

      this.$refs.FuelExpensesRowForm.$v.form.$touch();
      if (this.$refs.FuelExpensesRowForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.form.fuelExpenseId = this.$route.params.expenseId;
      this.$store
        .dispatch(SAVE_FUEL_EXPENSE_ROW, this.form)
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-fuel-expenses-row",
              params: { expenseId: this.form.fuelExpenseId, id: itemEdit.id },
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-fuel-expenses-row" });
          }
          if (next == "exit") {
            this.$router.push({
              name: "edit-fuel-expenses",
              params: { id: this.form.fuelExpenseId },
            });
          }
        })
        .catch((response) => {
          this.errors = this.$errors.format(response.data.errors);
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>
